import { PropsWithChildren } from 'react';

import { Label } from '@/components/Typography';

import * as styles from './InputWrapper.styles';

export interface InputWrapperProps {
  inputId: string;
  label?: string;
  helperText?: string;
  error?: string;
  showLabel?: boolean;
  isFocused?: boolean;
}

export const InputWrapper = (
  props: PropsWithChildren<InputWrapperProps>
) => {
  return (
    <div>
      <div className={styles.wrapper(props.showLabel)}>
        {props.label && (
          <Label htmlFor={props.inputId}>{props.label}</Label>
        )}
        {props.children}
      </div>
      {props.error && (
        <span className={styles.error}>{props.error}</span>
      )}
      {props.helperText && (
        <span className={styles.helperText}>{props.helperText}</span>
      )}
    </div>
  );
};
