import { PropsWithChildren } from 'react';

import { Info } from '@/components/Icons/Info';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { HeaderProps } from '../typography-elements/types';

import * as styles from './Label.styles';

interface LabelProps extends HeaderProps {
  htmlFor?: string;
}

export const Label = ({
  children,
  htmlFor,
}: PropsWithChildren<LabelProps>) => (
  <label htmlFor={htmlFor} className={styles.label}>
    {children}
  </label>
);

interface LabelWithTooltipProps extends LabelProps {
  id: string;
  message: string;
}

export const LabelWithTooltip = (
  props: PropsWithChildren<LabelWithTooltipProps>
) => (
  <div className={styles.tooltipWrapper}>
    <Label htmlFor={props.htmlFor}>{props.children}</Label>
    <span className={styles.tooltip}>
      <Tooltip
        message={props.message}
        id={props.id}
        className={styles.customClass}
      >
        <Info />
      </Tooltip>
    </span>
  </div>
);
