import { Typography } from '@/components/Typography/Typography';
import { FontWeight } from '@/styles/tokens';
import { MutableRefObject, PropsWithChildren, useState } from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';

interface TooltipComponentProps extends TooltipProps {
  message?: string;
  ref?: MutableRefObject<any>;
}

export const Tooltip = (
  props: PropsWithChildren<TooltipComponentProps>
) => {
  if (!props.message) return <>{props.children}</>;
  return (
    <>
      <span data-tip data-for={props.id}>
        {props.children}
      </span>
      <ReactTooltip
        disable={props.disable}
        place={props.place}
        id={props.id}
        type={props.type}
        className={props.className}
        effect="solid"
        scrollHide={false}
        resizeHide={false}
      >
        <Typography Element={'span'} fontWeight={FontWeight.S400}>
          {props.message}
        </Typography>
      </ReactTooltip>
    </>
  );
};

export const TooltipV2 = ({
  children,
  content,
}: {
  children: React.ReactNode;
  content: string;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="relative flex flex-col items-center group">
      {/* The component that will have the tooltip */}
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="cursor-pointer"
      >
        {children}
      </div>

      {/* Tooltip text */}
      {isHovered && (
        <div className="absolute bottom-full mb-2 z-10 p-2 min-w-max bg-gray-700 text-white text-sm rounded-md shadow-lg">
          {content}
        </div>
      )}
    </div>
  );
};
