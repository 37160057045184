import { InputWrapper } from '@/components/Input';
import { InputProps } from '../types';

import React, {
  ClipboardEvent,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';

import * as styles from './InputText.styles';

interface InputTextProps extends InputProps {
  autoFocus?: boolean;
  disabled?: boolean;
  maxLength?: number;
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onPaste?(e: ClipboardEvent<HTMLInputElement>): void;
}

export const InputText = (props: InputTextProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [hasFocus, setFocus] = useState(false);

  const id = useId();

  useEffect(() => {
    if (
      document.hasFocus() &&
      ref.current?.contains(document.activeElement)
    ) {
      setFocus(true);
    }
  }, []);

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFocus(false);
    props.onBlur && props.onBlur(e);
  };

  return (
    <InputWrapper
      label={props.label}
      inputId={props.id || id}
      isFocused={hasFocus}
      showLabel={Boolean(!!props.value)}
      error={props.error}
      helperText={props.helperText}
    >
      <input
        id={props.id || id}
        ref={ref}
        autoFocus={props.autoFocus}
        placeholder={props.placeholder}
        className={styles.input(props.disabled)}
        maxLength={props.maxLength}
        onPaste={props.onPaste}
        {...props}
        onKeyDown={props.onKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={props.onChange}
        type={props.type}
      />
    </InputWrapper>
  );
};
