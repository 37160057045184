import { css } from '@emotion/css';

import { Colors } from '@/styles/colors';
import { FontSize } from '@/styles/tokens';

export const input = (disabled = false) =>
  css({
    fontSize: FontSize.S16,
    border: `solid 2px ${Colors.red}`,
    color: disabled ? Colors.gray400 : Colors.gray700,
    '&:focus': {
      borderBottom: `solid 2px ${Colors.cornflowerBlue}`,
    },
  });
