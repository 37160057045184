import { Colors } from '@/styles/colors';
import { Spacings } from '@/styles/spacings';
import { FontFamily, FontSize, FontWeight } from '@/styles/tokens';
import { css } from '@emotion/css';

export const error = css({
  display: 'block',
  color: Colors.red,
  fontSize: FontSize.S12,
  fontWeight: FontWeight.S400,
  marginTop: Spacings.S4,
});

export const helperText = css({
  fontSize: FontSize.S14,
  fontWeight: FontWeight.S500,
  display: 'block',
  color: Colors.gray500,
  marginTop: Spacings.S16,
});

export const wrapper = (isFocused = false) =>
  css({
    position: 'relative',
    input: {
      transitionDuration: '0.6s',
      width: '100%',
      border: `1px solid ${Colors.gray200}`,
      padding: `${isFocused ? '20px' : Spacings.S16} ${
        Spacings.S16
      } ${Spacings.S16}`,
      fontFamily: FontFamily.Inter,
      fontSize: FontSize.S16,
      background: Colors.gray50,
      maxHeight: '54px',
    },
    label: {
      opacity: isFocused ? 1 : 0,
      transitionDuration: '0.6s',
      fontSize: FontSize.S10,
      position: 'absolute',
      top: 2,
      left: Spacings.S16,
    },
  });
