import { IconDefault } from '@/components/Icons/types';

export const ArrowLong = ({ scale = 100, ...props }: IconDefault) => (
  <svg
    onClick={props.onClick}
    width={(18 * scale) / 100}
    height={(18 * scale) / 100}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.75 9L2.25 9"
      stroke={props.color || '#52525B'}
      strokeLinecap="round"
    />
    <path
      d="M6.75 4.5L2.25 9L6.75 13.5"
      stroke={props.color || '#52525B'}
      strokeLinecap="round"
    />
  </svg>
);
