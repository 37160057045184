import { Colors } from '@/styles/colors';
import { Spacings } from '@/styles/spacings';
import { FontFamily, FontSize, FontWeight } from '@/styles/tokens';
import { css } from '@emotion/css';

export const tooltipWrapper = css({
  display: 'inline-block',
  position: 'relative',
});

export const tooltip = css({
  position: 'absolute',
  right: -12,
  top: -3,
});

export const customClass = css({
  maxWidth: 360,
  textAlign: 'center',
});

export const label = css({
  display: 'inline-flex',
  alignItems: 'center',
  color: Colors.gray600,
  fontWeight: FontWeight.S400,
  fontFamily: FontFamily.Inter,
  lineHeight: '14px',
  fontSize: FontSize.S14,
  svg: {
    marginRight: Spacings.S4,
  },
});
