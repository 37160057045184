export const Info = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 0C4.01109 0 3.0444 0.293245 2.22215 0.842652C1.3999 1.39206 0.759043 2.17295 0.380604 3.08658C0.00216642 4.00021 -0.0968502 5.00555 0.0960758 5.97545C0.289002 6.94535 0.765206 7.83627 1.46447 8.53553C2.16373 9.23479 3.05464 9.711 4.02455 9.90392C4.99445 10.0969 5.99979 9.99783 6.91342 9.6194C7.82705 9.24096 8.60794 8.60009 9.15735 7.77785C9.70675 6.9556 10 5.9889 10 5C10 4.34339 9.87067 3.69321 9.6194 3.08658C9.36812 2.47995 8.99983 1.92876 8.53553 1.46447C8.07124 1.00017 7.52004 0.631876 6.91342 0.380602C6.30679 0.129329 5.65661 0 5 0ZM5 9C4.20888 9 3.43552 8.7654 2.77772 8.32588C2.11992 7.88635 1.60723 7.26164 1.30448 6.53073C1.00173 5.79983 0.92252 4.99556 1.07686 4.21964C1.2312 3.44371 1.61216 2.73098 2.17157 2.17157C2.73098 1.61216 3.44372 1.2312 4.21964 1.07686C4.99556 0.922518 5.79983 1.00173 6.53073 1.30448C7.26164 1.60723 7.88635 2.11992 8.32588 2.77772C8.7654 3.43551 9 4.20887 9 5C9 6.06086 8.57857 7.07828 7.82843 7.82842C7.07828 8.57857 6.06087 9 5 9Z"
      fill="#A1A1AA"
    />
    <path
      d="M5 3.5C5.27614 3.5 5.5 3.27614 5.5 3C5.5 2.72386 5.27614 2.5 5 2.5C4.72386 2.5 4.5 2.72386 4.5 3C4.5 3.27614 4.72386 3.5 5 3.5Z"
      fill="#A1A1AA"
    />
    <path
      d="M5 4.00073C4.86739 4.00073 4.74021 4.05341 4.64645 4.14718C4.55268 4.24095 4.5 4.36812 4.5 4.50073V7.00073C4.5 7.13334 4.55268 7.26052 4.64645 7.35429C4.74021 7.44805 4.86739 7.50073 5 7.50073C5.13261 7.50073 5.25978 7.44805 5.35355 7.35429C5.44732 7.26052 5.5 7.13334 5.5 7.00073V4.50073C5.5 4.36812 5.44732 4.24095 5.35355 4.14718C5.25978 4.05341 5.13261 4.00073 5 4.00073Z"
      fill="#A1A1AA"
    />
  </svg>
);
